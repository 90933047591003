import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
// Components
import { ExistingQ, SendVerification, EnterCode } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Services
import { QService } from '../services/q-services'

type MethodTypes = 'EMAIL' | 'SMS' | 'DIRECT_SCALE'

interface SendCodeObjectProps {
  data: string
  method: MethodTypes
}

enum GetUser {
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
}

enum Verification {
  FAILURE = 'FAILURE',
  SENT = 'MESSAGE_SENT',
  COMPLETE = 'VERIFICATION_COMPLETE',
}

enum Code {
  COMPLETE = 'VERIFICATION_COMPLETE',
  NO_TOKEN = 'CAN_NOT_FIND_TOKEN',
}

const useValidateExistingUser = () => {
  const {
    handleUserLogout,
    referralData,
    setIsVerified,
    foundEmailButNotVerified,
    qUser,
  } = useAuthContext()
  const {
    prismicData: {
      prismicLoginPage: {
        customer_not_found_error,
        incorrect_code_error,
        incorrect_password_error,
        new_code_sent,
      },
    },
  }: any = usePrismic()

  const [step, setStep] = useState(1)
  const [dSUser, setDSUser] = useState(null)
  const [sendCodeObject, setSendCodeObject] =
    useState<SendCodeObjectProps>(null)

  const nextScreen = () => setStep(step + 1)

  const handleCancel = () => {
    handleUserLogout()
  }

  const getDSUser = async (username: string, showErrorAndReset: Function) => {
    try {
      let { directScaleInfo } = await QService.Auth.getDirectScaleUser(username)

      if (directScaleInfo.status === GetUser.SUCCESS) {
        // TODO - Only need to save emailAddress, primaryPhoneNumber, and dS_Username
        setDSUser(directScaleInfo.associate)
        nextScreen()
      }
      if (directScaleInfo.status === GetUser.NOT_FOUND) {
        showErrorAndReset(customer_not_found_error[0].text)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (foundEmailButNotVerified) {
      setDSUser(qUser)
      setStep(2)
    }
  }, [foundEmailButNotVerified, qUser])

  const sendVerification = async (
    method: MethodTypes,
    verification: string,
    showErrorAndReset?: Function
  ) => {
    try {
      let {
        loginValidationProcess: { status },
      } = await QService.Auth.loginValidationProcess(
        method,
        dSUser.dS_Username,
        verification
      )

      if (status === Verification.FAILURE) {
        setStep(2)
        showErrorAndReset(incorrect_password_error[0].text)
      }
      if (status === Verification.SENT) {
        if (step === 2) nextScreen()
      }
      if (status === Verification.COMPLETE) {
        setIsVerified()
        if (referralData?.type === 'ambassador') navigate('/enrollment')
        else navigate('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const enterCode = async (code: string, showErrorAndReset: Function) => {
    try {
      let { loginValidationToken } = await QService.Auth.confirmAccessCode(
        dSUser.dS_Username,
        code
      )
      const { status } = loginValidationToken
      if (status === Code.COMPLETE) {
        setIsVerified()
        if (referralData?.type === 'ambassador') navigate('/enrollment')
        else navigate('/')
      }
      if (status === Code.NO_TOKEN) {
        showErrorAndReset(incorrect_code_error[0].text)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const verifyAccountScreens: any = {
    1: (
      <ExistingQ
        cancel={handleCancel}
        getUserCallback={getDSUser}
        isOpen={step === 1}
      />
    ),
    2: (
      <SendVerification
        cancel={handleCancel}
        dsUser={dSUser}
        isOpen={step === 2}
        saveVerificationData={setSendCodeObject}
        sendVerificationCallback={sendVerification}
      />
    ),
    3: (
      <EnterCode
        cancel={handleCancel}
        enterCodeCallback={enterCode}
        isOpen={step === 3}
        resendCode={() =>
          sendVerification(sendCodeObject.method, sendCodeObject.data).then(
            () => alert(new_code_sent[0].text)
          )
        }
      />
    ),
  }

  return [verifyAccountScreens, step]
}

export default useValidateExistingUser
